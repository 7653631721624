// src/pages/newpage.js
import React from 'react';
import { Helmet } from 'react-helmet';

const Resume = () => {
  const redirectUrl = 'https://drive.google.com/file/d/10dBix-X7O5pUWe4FH47KslTK7KQubW-5/view';
  return (
    <div>
      <Helmet>
        <meta httpEquiv="refresh" content={`0;url=${redirectUrl}`} />
      </Helmet>
    </div>
  );
};

export default Resume;
